<template>
  <div class="neighbour">
    <div class="backbg neighbourhood">
      <div class="title">{{ title }}</div>
      <div class="content">
        <div class="flex-between-center shuliang">
          <div class="shu-left">{{ msg }}</div>
          <div class="boder_group">
            <div class="boder" v-for="(item, index) of numList" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="list">
          <div class="flex-between-center" v-for="(item, index) of list" :key="index" @click="getdetail(item.id)">
            <div class=" titleDot">{{ item.title }}</div>
            <div class="data">{{ item.publishTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'neighbour',
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    numList: {
      type: Array,
      default() {
        return [2, 2, 6]
      }
    },
    title: {
      type: String,
      default: "党员内部发布数据"
    },
    msg: {
      type: String,
      default: "已发布通知公告数量/条"
    },
    type: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {

    };
  },
  methods: {
    getdetail(id) {
      this.$emit("sureChoose", { id: id, type: this.type })
    }
  },
  mounted() {
  },
}
</script>
<style src="@/assets/css/contentfixed.css" scoped>
</style>
<style lang="scss" src="@/assets/css/neighbourhood.scss" scoped>
</style>
<style lang="scss" scoped>
</style>