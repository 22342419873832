<template>
  <div>
    <transition name='popup'>
      <div class="popup" v-if="showPopup" :style="'top:' + top + 'px'">
        <div class="top">
          <div class="title">{{ title }}</div>
          <div class="pdclose" @click="close()"></div>
        </div>
        <div class="detail" :style="padding ? '' : 'padding:0'">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'apopup',
  props: {
    top: {
      type: [String, Number],
      default: "105"
    },
    title: {
      type: String,
      default: ""
    },
    padding: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showPopup: false
    };
  },
  methods: {
    close() {
      this.showPopup = false;
    },
    show() {
      this.showPopup = true;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>