<template>
  <div>
    <transition name='right'>
      <div class="content_right">
        <neighbour title="村民留言箱" msg="居民留言数量/条" :numList="detail.villagerMessageNum" :list="detail.villagerMessageList"
          @sureChoose="sureChoose" :type="1" />
        <neighbour title="惠民政策数据" msg="惠民政策数量/条" :numList="detail.benefitingPolicyNum" :list="detail.benefitingPolicyList"
          @sureChoose="sureChoose" :type="2" />
        <neighbour title="村重大事务公开数据" msg="村重大事务数量/条" :numList="detail.majorAffairsNum" :list="detail.majorAffairsList"
          @sureChoose="sureChoose" :type="3" />
      </div>
    </transition>

    <div class="ImageText">
      <apopup ref="ImageText" :title="biImageTextType == 1 ? '村民留言箱' : biImageTextType == 2 ? '惠民政策数据' : '村重大事务公开数据'">
        <div class="detail_title">{{ biImageTextDetail.title }}</div>
        <div class="detail_time">{{ biImageTextDetail.publishTime }}</div>
        <div class="detail_main" v-html="content"></div>
      </apopup>
    </div>

  </div>
</template>

<script>
import {
  neighborbase, partybiImageTextdetail
} from '@/api/permission'
import neighbour from "@/components/neighbour";
import apopup from '@/components/apopup.vue'
export default {
  name: '',
  components: {
    neighbour, apopup
  },
  data() {
    return {
      villageId: "",
      detail: {},
      biImageTextType: false,
      biImageTextDetail: {},
      content: ""
    };
  },
  methods: {
    sureChoose(val) {
      console.log(val)
      this.getpartybiImageTextdetail(val);
    },
    getpartybiImageTextdetail(val) {
      this.$refs['ImageText'].close();
      partybiImageTextdetail({
        id: val.id,
      }).then(res => {
        this.$refs['ImageText'].show();
        this.biImageTextType = val.type;
        this.biImageTextDetail = res;
        let richtext = res.content || "";
        const regex = new RegExp("<img", "gi");
        richtext = richtext.replace(regex, `<img style="max-width: 100%;"`);
        this.content = richtext;
      })
    },
    getneighborbase() {
      neighborbase({ villageId: this.villageId }).then(res => {
        this.detail = res;
        this.detail.villagerMessageNum = res.villagerMessageNum ? res.villagerMessageNum.toString().split("") : [];
        this.detail.benefitingPolicyNum = res.benefitingPolicyNum ? res.benefitingPolicyNum.toString().split("") : [];
        this.detail.majorAffairsNum = res.majorAffairsNum ? res.majorAffairsNum.toString().split("") : [];
      })
    }
  },
  mounted() {
    this.villageId = this.$store.state.villageId;
    this.getneighborbase();

    this.$store.commit("SET_showtype", "4,1637670588280,1668750929544,1668752719107,1668754199819");

  },
}
</script>
<style src="@/assets/css/contentfixed.css" scoped>

</style>
<style lang="scss" scoped>
.ImageText {
  .detail {
    &_title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 7px;
    }

    &_time {
      font-size: 12px;
      color: #E2E7EA;
      margin-bottom: 15px;
    }

    &_main {
      white-space: pre-line;
      overflow-y: auto;
      max-height: calc(100vh - 267px);
    }
  }
}
</style>